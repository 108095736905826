// external imports
import React from 'react'
import moment from 'moment'
import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import striptags from 'striptags'
import styled, { css } from 'styled-components'
import {
  Formik,
  Form,
  useField,
  useFormikContext,
  Field,
  ErrorMessage,
} from 'formik'
import { Persist } from 'formik-persist'
import * as Yup from 'yup'
import 'yup-phone'

import axios from 'axios'

// helpers
import { up, addResponsivity } from '../lib/styles'
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  extractDataAndApplyLangs,
  withSameTags,
} from '../lib/helpers'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent } from '../lib/post'
import withExtraction from '../lib/withExtraction'
import pagesConfig from '../../pagesConfig'

// components
import {
  Title,
  Gap,
  Row,
  Col,
  SEO,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  WaveImage,
  RoundedImage,
  RoundWrapper,
  Page,
  PageContent,
  Button,
  HeaderBar,
} from '../components'
import { Checkbox, Input, PureCheckbox } from '../components/fields'
import T from '../lib/intl/T'
import { Grid, CustomizedGridItem } from '../components/CourseDetailBox'
import { categories } from '../courses'

import warningPath from '../data/images/icons/warning.svg'
import headerBarImageSrc from '../data/images/courses-top.png'

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: white;
    /* padding: 43px 60px; */
    padding: 32px 16px 80px;

    ${up('mobile')} {
      padding: 44px 56px 80px;
    }
  `}
`

const InputField = props => <Field type="text" component={Input} {...props} />
const CheckboxField = props =>
  <Field type="checkbox" component={Checkbox} {...props} />

const defaultIsRequiredMessage = 'Povinné pole, prosím vyplňte'
const defaultConditionIsRequiredMessage = 'Pro pokračování je potřeba souhlasit'

const NAME_ROW_BP = 450
const INVOICE_ADDRESS_BP = 640

const ADD_APPLICATION = `
    mutation AddApplication($data: ApplicationCreateInput!) {
      createApplication(data: $data) {
        name
        id
      }
    }
  `

function apiCall(query, variables = {}) {
  return axios({
    url: process.env.GATSBY_GQL_URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      variables,
      query,
    },
  })
}

class CourseTemplate extends React.Component {
  componentDidMount() {}

  handleSubmit = async (values, { setSubmitting }) => {
    const { course } = this.props.data

    values = { ...values }
    delete values.paymentCondition
    delete values.prerequisiteCondition
    delete values.gdprCondition

    values.birthday = moment(values.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')

    // console.log('values', values)
    try {
      const result = await apiCall(ADD_APPLICATION, {
        data: {
          ...values,
          courseTerm: {
            connect: {
              id: course.id,
            },
          },
        },
      })
      // console.log('result', result)
      const { data, errors } = result.data
      setSubmitting(false)

      if (errors) {
        if (errors.some(e => e.message)) {
          errors.forEach(e => {
            alert(`Formulář nebyl odeslán. ${e.message}`)
          })
        } else {
          alert(
            'Formulář nebyl odeslán. Zkontrolujte povinná pole. Pokud přetrvává, kontaktujte nás prosím.',
          )
        }
        return
      }

      const { category: categoryKey } = course.courseType

      if (data.createApplication.id) {
        navigate(`${pagesConfig['/thanks'].to.cs}?category=${categoryKey}`)
      }
    } catch (error) {
      console.log('errr', error)
      setSubmitting(false)
    }
  }

  render() {
    const {
      location: { pathname },
      intl: { language, t },
    } = this.props

    const { course, settings } = this.props.data

    const {
      prerequisiteText,
      hasPrerequisite,
      category: categoryKey,
    } = course.courseType

    const category = categories[categoryKey]

    const { gdprTerms, paymentTerms } = settings?.[0] || {}

    return (
      <>
        <SEO
          isArticle
          title={`${course.name} · Kurzy a akce`}
          pathname={pathname}
          description={striptags(course.aboutDesc)}
        />
        <HeaderBar>
          <HeaderBar.Content>
            <HeaderBar.Title>{category.label}</HeaderBar.Title>
            <HeaderBar.SubTitle>Přihláška na kurz</HeaderBar.SubTitle>
          </HeaderBar.Content>
          <HeaderBar.ImageWrapper mobileMaxWidth="450px">
            <img src={headerBarImageSrc} alt="" />
          </HeaderBar.ImageWrapper>
        </HeaderBar>

        <Page grey thinnerMobile>
          <PageContent>
            <Gap gap="55px" />
            <Wrapper>
              <Col>
                <Title.Section fontSize="45px">{course.name}</Title.Section>
                <Gap gap="8px" />
                <Text fontSize="20px">{course.fromToDesc}</Text>
                {hasPrerequisite && (
                  <>
                    <Gap gap="40px" />
                    <Row alignItems="center" mobileAlignItems="flex-start">
                      <img src={warningPath} />
                      <Gap gap="12px" />
                      <Text>{prerequisiteText}</Text>
                    </Row>
                    <Gap gap="50px" />
                  </>
                )}
              </Col>
              <Grid padding="0" mobilePadding="0">
                <CustomizedGridItem
                  col={1}
                  row={1}
                  text={course.aboutDesc}
                  title="Náplň"
                  withoutBorder
                />
                <CustomizedGridItem
                  col={1}
                  row={2}
                  text={course.quantityDesc}
                  title="Rozsah"
                  append={
                    (course.courseType.isAccredited ||
                      course.courseType.isAccreditedMPSV) &&
                    course.courseType.accreditationDocument && (
                      <Col>
                        <Gap gap="60px" mobileGap="18px" />
                        <Text.Small>
                          Kurz je{' '}
                          <Link
                            asA
                            href={
                              course.courseType.accreditationDocument.publicUrl
                            }
                            target="_blank"
                          >
                            akreditován
                            {course.courseType.isAccreditedMPSV
                              ? ' MPSV '
                              : ' MŠMT '}
                            ČR
                          </Link>
                          .
                        </Text.Small>
                      </Col>
                    )
                  }
                />

                {course.lecturers && course.lecturers.length ? (
                  <CustomizedGridItem
                    col={2}
                    row={1}
                    content={course.lecturers.map((lec, index) => (
                      <>
                        {index !== 0 && ', '}
                        <Link pageKey={Link.ABOUT} anchor={lec.anchor}>
                          {lec.fullName}
                        </Link>
                      </>
                    ))}
                    title="Lektoři"
                  />
                ) : (
                  <CustomizedGridItem
                    col={2}
                    row={1}
                    text={course.priceDesc}
                    title="Cena"
                  />
                )}

                <CustomizedGridItem
                  col={2}
                  row={2}
                  text={!course.lecturers?.length ? '' : course.priceDesc}
                  title={!course.lecturers?.length ? '' : 'Cena'}
                />
              </Grid>

              <Col>
                <Formik
                  // initialValues are required :(
                  initialValues={{
                    // first section
                    titleBefore: '',
                    name: '',
                    titleAfter: '',
                    phone: '',
                    birthday: '',
                    email: '',
                    street: '',
                    city: '',
                    zip: '',

                    // second section
                    invoiceName: '',
                    isInvoiceAddressSame: false,
                    invoiceStreet: '',
                    invoiceCity: '',
                    invoiceZip: '',
                    invoiceId: '',
                    invoiceVatId: '',
                    note: '',

                    // conditions
                    paymentCondition: false,
                    prerequisiteCondition: false,
                    gdprCondition: false,
                    marketingCondition: false,
                  }}
                  validationSchema={Yup.object({
                    // first section
                    name: Yup.string().required(defaultIsRequiredMessage),
                    street: Yup.string().required(defaultIsRequiredMessage),
                    phone: Yup.string()
                      .test(
                        'phone-check',
                        'Číslo není správně',
                        async value =>
                          await Yup.string()
                            .phone('CZ', true)
                            .isValid(value) ||
                          await Yup.string()
                            .phone('SK', true)
                            .isValid(value),
                      )
                      .required(defaultIsRequiredMessage),
                    email: Yup.string()
                      .email('Špatný formát emailu.')
                      .required(defaultIsRequiredMessage),
                    city: Yup.string().required(defaultIsRequiredMessage),
                    zip: Yup.string().required(defaultIsRequiredMessage),

                    // second section

                    // conditions
                    paymentCondition: Yup.bool().oneOf(
                      [true],
                      defaultConditionIsRequiredMessage,
                    ),
                    gdprCondition: Yup.bool().oneOf(
                      [true],
                      defaultConditionIsRequiredMessage,
                    ),
                  })}
                  validate={values => {
                    const errors = {}
                    if (!values.birthday) {
                      errors.birthday = defaultIsRequiredMessage
                    } else {
                      const trimmed = values.birthday.split(' ').join('')
                      const date = moment(trimmed, 'DD.MM.YYYY')
                      if (
                        !date.isValid(date) ||
                        trimmed.length < 8 ||
                        date.isAfter(moment().subtract(3, 'years')) ||
                        date.isBefore(moment().subtract(90, 'years'))
                      ) {
                        errors.birthday = 'Zadejte prosím ve formátu DD.MM.RRRR'
                      }
                    }

                    if (hasPrerequisite) {
                      if (!values.prerequisiteCondition) {
                        errors.prerequisiteCondition = defaultConditionIsRequiredMessage
                      }
                    }
                    return errors
                  }}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    submitForm,
                    isSubmitting,
                    setFieldValue,
                    ...rest
                  }) => (
                    // console.log('errors', errors, values, rest)
                    <Form>
                      <Persist name="course-form" />

                      <Gap gap="32px" />
                      <Title.Form>Osobní a kontaktní údaje</Title.Form>
                      <Gap gap="40px" />
                      <Row width="100%" responsive>
                        <Col width="48%" mobileWidth="auto">
                          <Row
                            position="relative"
                            justifyContent="space-between"
                            responsive
                            bp={NAME_ROW_BP}
                          >
                            <Col
                              shrink="1"
                              width="20%"
                              mobileWidth="auto"
                              bp={NAME_ROW_BP}
                            >
                              <InputField name="titleBefore" label="Titul" />
                            </Col>

                            <Gap gap="10px" />

                            <Col grow="1" shrink="10" ignoreMinContent>
                              <InputField
                                name="name"
                                label="Jméno a příjmení*"
                              />
                            </Col>

                            <Gap gap="10px" />

                            <Col
                              shrink="10"
                              width="20%"
                              mobileWidth="auto"
                              bp={NAME_ROW_BP}
                            >
                              <InputField name="titleAfter" label="Titul" />
                            </Col>
                          </Row>
                          <InputField
                            name="birthday"
                            label="Datum narození*"
                            placeholder="např. 20.10.1990"
                          />
                          <InputField
                            name="street"
                            label="Ulice, číslo popisné*"
                          />
                        </Col>

                        <Gap gap="32px" desktop />

                        <Col
                          grow="10"
                          ignoreMinContent
                          shrink="10"
                          mobileWidth="auto"
                        >
                          <InputField name="phone" label="Telefon*" />
                          <InputField name="email" label="Váš e-mail*" />

                          <Row>
                            <Col width="70%">
                              <InputField name="city" label="Město*" />
                            </Col>
                            <Gap gap="10px" />
                            <Col width="30%" ignoreMinContent>
                              <InputField name="zip" label="PSČ*" />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Gap gap="75px" />

                      <Title.Form>Fakturační údaje</Title.Form>
                      <Gap gap="32px" />

                      <Col>
                        <InputField
                          name="invoiceName"
                          label="Název firmy nebo instituce"
                        />

                        <Field
                          name="isInvoiceAddressSame"
                          type="checkbox"
                          component={Checkbox}
                          label="Fakturační adresa je shodná s kontaktní adresou"
                          sideEffect={({
                            value,
                            setFieldTouched,
                            setFieldValue,
                          }) => {
                            if (value) {
                              setFieldValue('invoiceStreet', values.street)
                              setFieldValue('invoiceCity', values.city)
                              setFieldValue('invoiceZip', values.zip)
                            }
                          }}
                        />

                        <Row responsive bp={INVOICE_ADDRESS_BP}>
                          <Col
                            width="48%"
                            shrink="10"
                            bp={INVOICE_ADDRESS_BP}
                            mobileWidth="auto"
                          >
                            <InputField
                              name="invoiceStreet"
                              label="Ulice, číslo popisné"
                              disabled={values.isInvoiceAddressSame}
                              value={
                                values.isInvoiceAddressSame
                                  ? values.street
                                  : values.invoiceStreet
                              }
                            />
                          </Col>

                          <Gap gap="30px" bp={INVOICE_ADDRESS_BP} desktop />

                          <Row
                            width="48%"
                            shrink="10"
                            grow="1"
                            bp={INVOICE_ADDRESS_BP}
                            mobileWidth="auto"
                          >
                            <Col width="70%" shrink="10">
                              <InputField
                                name="invoiceCity"
                                label="Město"
                                disabled={values.isInvoiceAddressSame}
                                value={
                                  values.isInvoiceAddressSame
                                    ? values.city
                                    : values.invoiceCity
                                }
                              />
                            </Col>

                            <Gap gap="10px" />

                            <Col width="30%" ignoreMinContent shrink="10">
                              <InputField
                                name="invoiceZip"
                                label="PSČ"
                                disabled={values.isInvoiceAddressSame}
                                value={
                                  values.isInvoiceAddressSame
                                    ? values.zip
                                    : values.invoiceZip
                                }
                              />
                            </Col>
                          </Row>
                        </Row>

                        <Row responsive>
                          <Col width="48%" shrink="10" mobileWidth="auto">
                            <InputField name="invoiceId" label="IČ" />
                          </Col>
                          <Gap gap="32px" desktop />
                          <Col
                            width="48%"
                            shrink="10"
                            mobileWidth="auto"
                            grow="1"
                          >
                            <InputField name="invoiceVatId" label="DIČ" />
                          </Col>
                        </Row>
                        <InputField
                          name="note"
                          label="Vaše poznámka"
                          asTextArea
                        />
                      </Col>

                      <Gap gap="48px" />

                      <CheckboxField
                        name="paymentCondition"
                        label={(
                          <>
                            Souhlasím s{' '}
                            <Link
                              asA
                              href={paymentTerms?.file?.file?.publicURL}
                              target="_blank"
                            >
                              platebními podmínkami
                            </Link>
                            *
                          </>
                        )}
                      />
                      <CheckboxField
                        name="gdprCondition"
                        label={(
                          <>
                            Souhlasím se zpracováním osobních údajů pro účely
                            vytvoření certifikátu a seznámil(a) jsem se s{' '}
                            <Link
                              asA
                              href={gdprTerms?.file?.file?.publicURL}
                              target="_blank"
                            >
                              Informacemi o zpracování osobních údajů a
                              informacemi o svých právech v souvislosti se
                              zpracováním osobních údajů
                            </Link>{' '}
                            ze strany ATC*
                          </>
                        )}
                      />

                      {hasPrerequisite && (
                        <CheckboxField
                          name="prerequisiteCondition"
                          label={`${prerequisiteText}*`}
                        />
                      )}

                      <CheckboxField
                        name="marketingCondition"
                        label={(
                          <>
                            Souhlasím se zasíláním informací o vzdělávacích
                            programech, seminářích a možnostech zapojení se do
                            projektů implementace metody instrumentálního
                            uvědomování, a pro tento účel souhlasím se
                            zpracováním svých výše uvedených osobních údajů v
                            souladu s právními předpisy, především zákonem č.
                            101/2000 Sb., o ochraně osobních údajů, v platném
                            znění a s Nařízením Evropského parlamentu a Rady
                            (EU) č. 2016/679, obecným nařízením o ochraně
                            osobních údajů. Tento souhlas uděluji na dobu 7 let.
                          </>
                        )}
                      />

                      <Gap gap="54px" />

                      <Button.PrimaryGreen
                        onClick={submitForm}
                        disabled={isSubmitting}
                        iconLeft="signUp"
                      >
                        odeslat přihlášku{' '}
                      </Button.PrimaryGreen>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Wrapper>
            <Gap gap="72px" />
          </PageContent>
        </Page>
        <Gap gap="62px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(CourseTemplate))

export const pageQuery = graphql`
  query($id: ID = "") {
    ks {
      course: CourseTerm(where: { id: $id }) {
        ...CourseTermFragment
        ...CourseTermNestedFragment
      }
      settings: allSettings {
        paymentTerms {
          ...DocumentFragment
        }
        gdprTerms {
          ...DocumentFragment
        }
      }
    }

    # contentImages: allFile(
    #   filter: { relativePath: { regex: "/^uploaded-image-/" } }
    # ) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }
  }
`
